@charset "UTF-8";
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

@font-face {
  font-family: "Roboto";
  src: url("../fonts/../fonts/Robotobold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Yandex";
  src: url("../fonts/../fonts/Yandexsansdisplaybold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Yandex";
  src: url("../fonts/../fonts/Yandexsansdisplayregular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
/**
 * Сброс стилей
 **/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
caption,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

input {
  outline: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}

html {
  overflow: overlay;
}

body {
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: "Yandex", sans-serif;
  background-color: #fff;
  color: #222426;
  box-sizing: border-box;
  /*   -ms-overflow-style: none;
    scrollbar-width: none; */
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  transition: all 0.3s;
}

p {
  font-size: 18px;
  padding-bottom: 7px;
  line-height: 1.6;
  text-align: justify;
}

ul {
  padding: 7px 0 14px 30px;
  font-size: 18px;
  list-style-type: disc;
}

ol {
  padding: 7px 0 14px 30px;
  font-size: 18px;
  list-style-type: decimal;
}

ul li, ol li {
  line-height: 1.6;
}

.content {
  margin-bottom: 200px;
}

.page {
  margin-left: 375px;
  max-width: 1200px;
}

.block-title {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 40px;
}

.section-block {
  margin: 60px 0;
}

.block-centered {
  display: block;
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .page {
    margin-left: 270px;
  }
}
@media only screen and (max-width: 992px) {
  .page {
    margin-left: 0px;
    margin: 0 auto;
  }
  .block-title {
    font-size: 26px;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 573px) {
  .block-title {
    font-size: 22px;
  }
  .section-block {
    margin: 30px 0;
  }
  p {
    font-size: 16px;
  }
  ul, ol {
    font-size: 16px;
  }
}