
html{
    overflow:overlay;
}

body {
    overflow-x: hidden;
  
}
// body{height: 100%!important}  
//body::-webkit-scrollbar { width: 0; }



*,
*::before,
*::after {
    box-sizing: inherit;
   
    
}

body {
    margin: 0;
    font-family: "Yandex", sans-serif;
    background-color: #fff;
    color: $font;
    box-sizing: border-box;
/*   -ms-overflow-style: none;
  scrollbar-width: none; */
 
 
}




img {
    max-width: 100%;
    height: auto;
}

a {
    text-decoration: none;
    transition: all .3s;
}

p {
    font-size: 18px;
    padding-bottom: 7px;
    line-height: 1.6;
    text-align: justify;
}
ul {
    padding: 7px 0 14px 30px;
    font-size: 18px;
    list-style-type: disc;
}
ol{
    padding: 7px 0 14px 30px;
    font-size: 18px;
    list-style-type:decimal;
}

ul li, ol li {
    line-height: 1.6;
}

.content {
    margin-bottom: 200px;
  
}

.page {
    margin-left: 375px;
    max-width: 1200px;


   

}

.block-title {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 40px;
}

.section-block {
    margin: 60px 0;
}

.block-centered {
    display: block;
    text-align: center;
}

@media only screen and (max-width : 1200px) {
    .page {
        margin-left: 270px;
    }

}

@media only screen and (max-width : 992px) {
    .page {
        margin-left: 0px;
        margin: 0 auto;
    }

    .block-title {
        font-size: 26px;
        margin-bottom: 30px;
    }


}

@media only screen and (max-width : 573px) {
    .block-title {
        font-size: 22px;
    }

    .section-block {
        margin: 30px 0;
    }

    p {
        font-size: 16px;
    }
    ul, ol {

        font-size: 16px;

    }

}