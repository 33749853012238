// COLORS

$black: #000;
$white: #fff;
$blue: #0A77F5;
$dblue: #323555;
$font: #222426;
$orange: #f15a25;
$green: #549625;
$gray: #F2F3F7;
